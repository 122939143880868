export const convertSelectValueToObj = (arr: string[], key: string) => {
  return arr?.map((item) => ({
    [key]: item
  }))
}

export const convertObjToSelectValue = (
  arr: { id: string; name: string }[]
): string[] => {
  return arr?.map((item) => item.id)
}

export const isEmptyValue = <T = any>(value: T) => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value as any).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  )
}

export const isZeroValue = <T = any>(value: T) => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'number' && value === 0) ||
    (typeof value === 'string' && Number(value) === 0)
  )
}

export const omitEmptyValues = (values: Record<string, any>) =>
  Object.entries(values).reduce((acc, [key, value]) => {
    if (!isEmptyValue(value)) acc[key] = value

    return acc
  }, {})

export const countActiveFilters = (values: any): number =>
  Object.keys(values)
    .map((key) => {
      if (
        (key === 'dietary' && values?.dietary?.length === 0) ||
        (key === 'bake_category' && values?.bake_category?.length === 0)
      ) {
        return null
      }

      return values[key]
    })
    .filter(Boolean).length

export const extractText = (
  element: string | JSX.Element | JSX.Element[]
): string => {
  if (typeof element === 'string') {
    return element
  }
  if (Array.isArray(element)) {
    return element?.map(extractText)?.join('')
  }
  if (element?.props?.children) {
    return extractText(element?.props?.children)
  }

  return ''
}

export const getIdFromText = (
  text: string | JSX.Element | JSX.Element[]
): string => {
  const plainText = extractText(text)
  return plainText.toLowerCase().replace(/\s+/g, '-')
}
