import React, { ReactElement } from 'react'

import { dehydrate, QueryClient } from 'react-query'

import MainLayout from '~/components/layouts/MainLayout'
import StaticPages from '~/views/StaticPages/StaticPages.view'

import Api from '~/services/Api'
import Deserializer from '~/services/Deserializer'
import { staticPageConfig } from '~/services/Query/static-pages/static-pages.query'

import { NextPageExtended } from '~/types/interfaces/nextjs'

const StaticPage: NextPageExtended = () => {
  return <StaticPages />
}

StaticPage.getLayout = (page: ReactElement) => <MainLayout>{page}</MainLayout>

export async function getStaticPaths() {
  const res = await Api.getAllStaticPages()
  const posts = await Deserializer.deserialize(res)

  const paths = posts
    .map((post) =>
      post?.static_pages?.map((item) => ({
        params: {
          slug: item?.slug
        }
      }))
    )
    ?.reduce((a, b) => {
      return a.concat(b)
    })

  return { paths, fallback: true }
}

export async function getStaticProps({ params: { slug } }) {
  try {
    const queryClient = new QueryClient()

    await queryClient.prefetchQuery(
      [staticPageConfig.getStaticPage.key, slug],
      staticPageConfig.getStaticPage.request
    )

    return {
      props: { dehydratedState: dehydrate(queryClient) }
    }
  } catch (error) {
    return { notFound: true }
  }
}

export default StaticPage
