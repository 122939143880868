import React, { FC } from 'react'

import { Box } from '@mui/material'
import parser, {
  attributesToProps,
  domToReact,
  HTMLReactParserOptions
} from 'html-react-parser'

import { getIdFromText } from '~/utils/helpers/functions.helpers'

import Link from './Link'

interface ReactHtmlParserProps {
  data: string
  options?: HTMLReactParserOptions
}

const headerElementTags = ['h1', 'h2', 'h3', 'h4']

const ReactHtmlParser: FC<ReactHtmlParserProps> = ({ data, options = {} }) => {
  const transform = (node) => {
    if (options?.replace) {
      const transformed = options.replace(node)

      if (transformed) return transformed
    }

    if (node.type === 'tag' && node.name === 'img') {
      const attr = attributesToProps(node.attribs)
      return (
        <img
          {...attr}
          src={attr.src}
          alt={attr.alt}
          title={attr.title}
          loading="lazy"
          style={{
            ...attr.style,
            maxWidth: '100%',
            width: 'min(100%, 100vw)',
            height: 'auto'
          }}
        />
      )
    }
    if (node.type === 'tag' && headerElementTags.includes(node.name)) {
      const idFromText = getIdFromText(domToReact(node.children))

      if (node.name === 'h1') {
        return (
          <h1 id={idFromText} style={{ lineHeight: '1.2em' }}>
            {domToReact(node.children)}
          </h1>
        )
      }
      return <node.name id={idFromText}>{domToReact(node.children)}</node.name>
    }

    if (node.type === 'tag' && node.name === 'table') {
      const attr = attributesToProps(node.attribs)

      return (
        <Box
          overflow="scroll"
          width={`min(90vw, calc(${attr.style?.width} + 15px))`}
        >
          <table {...attr}>{domToReact(node.children)}</table>
        </Box>
      )
    }

    if (node.type === 'tag' && node.name === 'iframe') {
      const attr = attributesToProps(node.attribs)

      return (
        <iframe {...attr} title={attr.title} style={{ maxWidth: '100%' }}>
          {domToReact(node.children)}
        </iframe>
      )
    }

    if (node.type === 'tag' && node.name === 'a') {
      if (node.attribs?.href && node.attribs.href[0] === '/') {
        return (
          <Link noLinkStyle href={node.attribs.href}>
            {domToReact(node.children)}
          </Link>
        )
      }

      return node
    }

    return node
  }

  return <>{parser(data, { ...options, replace: transform })}</>
}

export default ReactHtmlParser
