import { useRouter } from 'next/router'
import React, { FC } from 'react'

import { Box, CircularProgress, Container, Typography } from '@mui/material'
import { NextSeo } from 'next-seo'

import ReactHtmlParser from '~/components/base/ReactHtmlParser'
import Text from '~/components/base/Text'
import Center from '~/components/common/Center'

import { useGetStaticPageQuery } from '~/services/Query/static-pages/static-pages.query'

const StaticPages: FC = () => {
  const router = useRouter()
  const slug = router.query?.slug as string

  const { data: staticPage } = useGetStaticPageQuery({
    slug,
    options: {
      onError: () => {
        router.push('/')
      }
    }
  })

  const { title, body, meta } = staticPage || {}
  const { meta_description, meta_title, meta_h1 } = meta || {}

  if (router.isFallback) {
    return (
      <Center minHeight={400}>
        <CircularProgress size={50} />
      </Center>
    )
  }

  return (
    <>
      <NextSeo
        title={meta_title}
        description={meta_description}
        openGraph={{
          title: meta_title,
          description: meta_description
        }}
        canonical={`${process.env.NEXT_PUBLIC_APP}/${slug}`}
      />

      <Container maxWidth="lg">
        <Typography component="h1" sx={{ display: 'none' }}>
          {meta_h1}
        </Typography>

        <Text
          size={{ xs: 'h2' }}
          textAlign="center"
          mt={7}
          mb={4}
          fontWeight={700}
        >
          {title}
        </Text>

        <Box maxWidth={730} m="0 auto" mb={10} sx={{ wordBreak: 'break-word' }}>
          <ReactHtmlParser data={String(body)} />
        </Box>
      </Container>
    </>
  )
}

export default StaticPages
